import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/farms',
    name: 'Farms',
    component: () => import('../views/Farms.vue')
  },
  {
    path: '/heritage-trees',
    name: 'HeritageTrees',
    component: () => import('../views/HeritageTrees.vue')
  },
  {
    path: '/heritage-trees/:id',
    name: 'HeritageTreesDetails',
    component: () => import('../views/TreeProfile.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/la-finca-trees',
    name: 'LaFincaTrees',
    component: () => import('../views/LaFincaTrees.vue')
  },
  {
    path: '/tree-profile',
    name: 'TreeProfile',
    component: () => import('../views/TreeProfile.vue')
  },
  {
    path: '/my-trees',
    name: 'MyTrees',
    component: () => import('../views/MyTrees.vue')
  },
  {
    path: '/my-tree-profile',
    name: 'MyTreeProfile',
    component: () => import('../views/MyTreeProfile.vue')
  },
  {
    path: '/grower',
    name: 'Grower',
    component: () => import('../views/Grower.vue')
  },
  {
    path: '/grower-profile',
    name: 'GrowerProfile',
    component: () => import('../views/GrowerProfile.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
